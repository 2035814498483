import { HeartAddToFav } from 'components/HeartAddToFav/HeartAddToFav';
import { IconPDF } from 'components/IconPDF/IconPDF';
import React from 'react';
import { Balkon } from 'shared/svg/balkon';
import { Ogrodek } from 'shared/svg/ogrodek';
import { Amenities, IsFree, LastColWrapper } from 'styles/Table.styled';
import {
    customFormatNumber,
    includesFilterFunction,
    toLocale,
} from 'utils/filters';
import { Featured } from './svg/featured';

export const orderByFn = (
    rows: any[],
    sortFn: any[],
    isDescending: boolean[],
) => {
    const featuredItems: any[] = [];
    const normalItems: any[] = [];

    rows.map((item: { original: { flatFeatured: boolean } }) => {
        item.original.flatFeatured
            ? featuredItems.push(item)
            : normalItems.push(item);
    });

    if (isDescending[0]) {
        featuredItems.sort(sortFn[0]);
        normalItems.sort(sortFn[0]);
    } else {
        featuredItems.sort(sortFn[0]).reverse();
        normalItems.sort(sortFn[0]).reverse();
    }
    return featuredItems.concat(normalItems);
};

export const columns = [
    {
        Header: 'Budynek',
        accessor: 'investmentBuilding',
        filter: includesFilterFunction,
    },
    {
        Header: () => (
            <span>
                Numer <br /> mieszkania
            </span>
        ),
        disableFilters: true,
        accessor: 'flatNumber',
    },
    {
        Header: 'Piętro',
        accessor: 'buildingFloor',
        filter: includesFilterFunction,
    },
    {
        Header: 'Ilość pokoi',
        accessor: 'flatRoomsQuantity',
        filter: includesFilterFunction,
    },
    {
        accessor: 'flatArea',
        Header: 'Metraż',
        filter: 'between',
        Cell: (data: any) => {
            return (
                <span>
                    {data?.cell?.value} m<sup>2</sup>
                </span>
            );
        },
    },
    {
        accessor: 'priceMeter',
        Header: () => (
            <span>
                Cena <br /> za metr{'\u00B2'}
            </span>
        ),
        Cell: (data: any) => {
            return (
                <span>
                    {data?.cell?.value
                        ? `${toLocale(data?.cell?.value)} zł`
                        : '-'}
                </span>
            );
        },
    },
    {
        Header: 'Cena',
        accessor: 'flatPrice',
        filter: 'between',
        Cell: (data: any) => {
            return (
                <span>
                    {data?.cell?.value
                        ? `${customFormatNumber(data?.cell?.value)} zł`
                        : '-'}{' '}
                </span>
            );
        },
    },
    {
        Header: 'Zalety',
        accessor: 'flatAmenities',
        filter: includesFilterFunction,
        Cell: (data: any) => {
            return (
                <Amenities>
                    {data?.row?.original?.flatAmenities === 'Ogródek' && (
                        <Ogrodek />
                    )}
                    {data?.row?.original?.flatAmenities === 'Balkon' && (
                        <Balkon />
                    )}
                    {data?.row?.original?.flatFeatured && <Featured />}
                </Amenities>
            );
        },
    },
    {
        Header: 'Status',
        accessor: 'flatStatus',
        sortType: (rowA: any, rowB: any) => {
            const order = {
                Wolne: 1,
                Zarezerwowane: 2,
                Sprzedane: 3,
            };

            const a = rowA.original.flatStatus;
            const b = rowB.original.flatStatus;

            //@ts-expect-error
            return order[a] - order[b];
        },
        disableFilters: true,
        filterable: false,
        Cell: (data: any) => {
            return (
                <IsFree isFree={data?.row?.original?.flatStatus}>
                    {data?.row?.original?.flatStatus}
                </IsFree>
            );
        },
    },
    {
        Header: () => null,
        id: 'additional',
        Cell: (data: any) => {
            return (
                <LastColWrapper onClick={(e) => e.stopPropagation()}>
                    <HeartAddToFav
                        building={data?.row?.original?.investmentBuilding}
                        id={data?.row?.original?.flatNumber}
                        size={24}
                    />
                    {(data?.row?.original?.pdfBrochure?.sourceUrl ||
                        data?.row?.original?.pdfBrochure?.mediaItemUrl) && (
                        <IconPDF
                            link={
                                data?.row?.original?.pdfBrochure?.sourceUrl ||
                                data?.row?.original?.pdfBrochure?.mediaItemUrl
                            }
                            size={24}
                        />
                    )}
                </LastColWrapper>
            );
        },
    },
];
